import httpClient  from '.'; 
const API_CONTROLLER = 'setting-job-fee/'; 


export default { 
    delete(id){ 
        let url = API_CONTROLLER + 'delete';
        return httpClient.delete(url, {
            params: {
                id: id
            }
        });
    },

    add(data) { 
        let url = API_CONTROLLER + 'add';
        return httpClient.post(url, data);
    },

    edit(data) { 
        let url = API_CONTROLLER + 'edit';
        return httpClient.post(url, data);
    },

    getById(id) { 
        let url = API_CONTROLLER + 'get-by-id';
        return httpClient.get(url, {
            params: {
                id: id
            }
        });
    },

    getAll(branchId) { 
        let url = API_CONTROLLER + 'get-all';
        return httpClient.get(url, {
            params: {
                branchId: branchId
            }
        });
    },

    getSetting(orderId, typeOfTransport, goodsTypeId, unitId) { 
        let url = API_CONTROLLER + 'get-setting';
        return httpClient.get(url, {
            params: {
                orderId: orderId,
                typeOfTransport: typeOfTransport,
                goodsTypeId: goodsTypeId,
                unitId: unitId
            }
        });
    },
}