<template>
    <div class="page-content">
        <div class="grid">
            <div class="row no-gutters page-list-header">
                <div class="col l-6 m-6 c-12">
                    <h4 class="page-list-title"><md-icon>list_alt</md-icon> <span>Danh sách lệnh điều động phương tiện</span></h4>
                </div>
                <div class="col l-6 m-6 c-12">
                    <div class="page-list-action"> 
                        <md-button @click="btnSearch()" class="md-raised md-primary">
                            Tìm kiếm
                            <md-tooltip>Click để tìm kiếm</md-tooltip>
                        </md-button>
                        <md-button v-shortkey="['ctrl', 'r']" @shortkey="refresh()" @click="refresh()" class="md-icon-button md-dense md-raised md-primary">
                            <md-icon>cached</md-icon>
                            <md-tooltip>Làm mới (Ctrl + R)</md-tooltip>
                        </md-button>
                    </div>
                </div>
            </div>
            <div class="page-list-body">
                <div class="page-list-search">
                    <div class="row">
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label for="branchId">Chi nhánh</label>
                                <md-select v-model="search.branchId" name="branchId" id="branchId">
                                    <md-option v-for="b in branchs" :key="b.id" :value="b.id">{{b.branchName}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="orderName" @md-selected="getOrderSelected" :md-options="orders" @md-changed="getOrders"  @md-opened="getOrders">
                                    <label>Tất cả đơn hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.orderCode }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openOrder()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <div class="form-control">
                                <md-autocomplete v-model="customerName" @md-selected="getCustomerSelected" :md-options="customers" @md-changed="getCustomers"  @md-opened="getCustomers">
                                    <label>Tất cả khách hàng</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                        <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                        "{{ term }}" Không tìm thấy!
                                    </template>
                                </md-autocomplete>
                                <md-button @click="openCustomer()" class="md-icon-button">
                                    <md-icon>manage_search</md-icon>
                                    <md-tooltip>Tìm khách hàng</md-tooltip>
                                </md-button>
                            </div>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label for="status">Trạng thái</label>
                                <md-select v-model="search.status" name="status" id="status">
                                    <md-option :value="0">Tất cả</md-option>
                                    <md-option :value="1">Chưa điều xe</md-option>
                                    <md-option :value="2">Đã điều tròn tua</md-option>
                                    <md-option :value="3">Chưa trả hàng/trả rỗng</md-option>
                                    <md-option :value="4">Đã điều 1 chặng</md-option>
                                </md-select>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-datepicker v-model="search.fromDate" :md-model-type="String" md-immediately :class="{'md-invalid': submitted && search.fromDate == null }">
                                <label>Từ ngày</label>
                            </md-datepicker>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-datepicker v-model="search.toDate" :md-model-type="String" md-immediately :class="{'md-invalid': submitted && search.toDate == null }">
                                <label>Tới ngày</label>
                            </md-datepicker>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label>Tìm theo số bill/booking/số tờ khai</label>
                                <md-input v-model="search.keyword"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label>Tìm theo số cont</label>
                                <md-input v-model="search.serial"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label>Tìm theo id cont/kiện</label>
                                <md-input v-model="search.code"></md-input>
                            </md-field>
                        </div>
                        <div class="col l-2 m-2 c-12">
                            <md-field>
                                <label for="planId">Loại hàng</label>
                                <md-select v-model="search.planId" name="planId" id="planId">
                                    <md-option :value="0">Tất cả</md-option>
                                    <md-option v-for="b in planList" :key="b.id" :value="b.id">{{b.text}}</md-option>
                                </md-select>
                            </md-field>
                        </div>
                    </div>
                </div>
                <div class="table-content" style="padding-bottom: 0;">
                    <div id="table-scroll" class="table-scroll" style="height: calc(100vh - 319px); overflow-x: auto; width: 100%;">
                        <table id="main-table" class="order-vehicle main-table" >
                            <thead>
                                <tr>
                                    <th class="sticky-col" rowspan="3" style="background-color: #DDDDDD; z-index: 999; width: 50px;">#</th>
                                    <th class="sticky-col-2" rowspan="3" style="background-color: #DDDDDD; z-index: 999; width: 100px;">HÀNH ĐỘNG</th>
                                    <th rowspan="3" style="width: 150px; background-color: #E6B9A6; color: red; z-index: 998;">MÃ ĐIỀU ĐỘNG</th>
                                    <th rowspan="3" style="width: 150px; background-color: #E6B9A6; color: red; z-index: 998;">TRẠNG THÁI</th>
                                    <th rowspan="3" style="width: 150px; background-color: #E6B9A6; color: red; z-index: 998;">LOẠI ĐIỀU ĐỘNG</th>
                                    <th rowspan="3" style="width: 150px; background-color: #E6B9A6; color: red; z-index: 998;">VENDOR</th>
                                    <th colspan="10" style="background-color: #FFD0D0;z-index: 998;">THÔNG TIN BILL/BOOKING</th>
                                    <th colspan="3" style="background-color: #BBE9FF;z-index: 998;">ĐỊA ĐIỂM GIAO NHẬN</th>
                                    <th colspan="4" style="background-color: #E0FBE2;z-index: 998;">THÔNG TIN ĐIỀU ĐỘNG</th>

                                    <th rowspan="3" style="width: 150px; background-color: #E6B9A6; color: red; z-index: 998;">Tạo đơn hàng</th>
                                    <th rowspan="3" style="width: 150px; background-color: #E6B9A6; color: red; z-index: 998;">Ghi chú</th>
                                </tr>
                                <tr>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Ngày thực hiện</th>
                                    <th style="width: 150px; background-color: #FFD0D0;z-index: 998;">Khách hàng</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Loại hình</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">KL (tấn)</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Bill/Booking</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Mã lô hàng</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Loại cont</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Số cont</th>
                                    <th style="width: 100px; background-color: #FFD0D0;z-index: 998;">Số seal</th>
                                    <th style="width: 100px; background-color: #FFD0D0; color: red;z-index: 998;">Closing time</th>

                                    <th style="width: 100px; background-color: #BBE9FF;z-index: 998;">Nơi nhận cont</th>
                                    <th style="width: 100px; background-color: #BBE9FF;z-index: 998;">Kho đóng/rút hàng</th>
                                    <th style="width: 100px; background-color: #BBE9FF;z-index: 998;">Nơi trả cont</th>

                                    <th style="width: 150px; background-color: #E0FBE2;z-index: 998;">Số xe</th>
                                    <th style="width: 150px; background-color: #E0FBE2;z-index: 998;">Tên lái xe</th>
                                    <th style="width: 150px; background-color: #E0FBE2;z-index: 998;">Số điện thoại</th>
                                    <th style="width: 150px; background-color: #E0FBE2;z-index: 998;">Mooc</th>
                                </tr>
                            </thead>
                            <tbody v-if="loadding == true" style="height: 150px;">
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                            </tbody>
                            <tbody v-else-if="loadding == false && pager.totalPages == 0" style="height: 150px;">
                                <tr><td colspan="100" class="no-data" style="text-align: center;"><span>Không có dữ liệu</span></td></tr>
                            </tbody>
                            <tbody v-else-if="loadding == false && pager.totalItems > 0">
                                <tr v-for="(item, index) in data" :key="'index-' + index" :class="{ 'odd': index % 2 !== 0 }">
                                    <td style="z-index: 998;" class="center sticky-col">{{index + 1}}</td>
                                    <td style="width: 10px; z-index: 998; text-align: center;" class="action sticky-col-2">
                                        <div style="display: flex; align-items: center; justify-content: center;">
                                            <div v-if="item.job == null && item.jobVendor == null">
                                                <md-menu md-direction="bottom-end" :mdCloseOnClick="closeOnClick" :mdCloseOnSelect="closeOnSelect">
                                                    <md-button style="background-color: #AD49E1 !important;" md-menu-trigger class="md-fab md-mini md-second">
                                                        <md-icon>local_shipping</md-icon>
                                                        <md-tooltip>Điều xe</md-tooltip>
                                                    </md-button>
                                                    <md-menu-content>
                                                        <md-menu-item @click="addJob(item)">Xe PPL</md-menu-item>
                                                        <md-menu-item @click="addVendor(item)">Xe ngoài</md-menu-item>
                                                    </md-menu-content>
                                                </md-menu>
                                            </div>
                                            <div v-else-if="item.job !== null && item.job.status !== 2">
                                                <md-button @click="editJob(item)" class="md-fab md-mini md-second">
                                                    <md-icon>edit</md-icon>
                                                    <md-tooltip>Sửa điều xe</md-tooltip>
                                                </md-button>
                                            </div>
                                            <div v-else-if="item.job !== null && item.job.status == 2">
                                                <md-button disabled class="md-fab md-mini md-second">
                                                    <md-icon>edit</md-icon>
                                                    <md-tooltip>Sửa điều xe</md-tooltip>
                                                </md-button>
                                            </div>
                                            <div v-else-if="item.jobVendor !== null">
                                                <md-button @click="editJobVendor(item)" class="md-fab md-mini md-second">
                                                    <md-icon>edit</md-icon>
                                                    <md-tooltip>Sửa điều xe</md-tooltip>
                                                </md-button>
                                            </div>
                                            <div>
                                                <md-button style="background-color: #48CFCB !important;" @click="viewAttachFile(item)" class="md-fab md-mini md-second">
                                                    <md-icon>attach_file</md-icon> 
                                                    <md-tooltip>Click để xem file đính kèm</md-tooltip>
                                                </md-button>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="center">
                                        <span v-if="item.job !== null">
                                            <a @click="viewJob(item.job.id)">{{  item.job.jobCode }}</a>
                                        </span>
                                        <span v-else-if="item.jobVendor !== null">
                                            <a @click="viewJobVendor(item)">{{  item.jobVendor.jobCode }}</a>
                                        </span>
                                    </td>
                                    <td class="center" style="z-index: 9995;">
                                        <md-chip v-if="item.job !== null && item.job.status == 2" style="background-color: #BEDC74; width: 110px; text-align: center;">Hoàn thành</md-chip>
                                        <md-chip v-else-if="item.job !== null && item.job.status !== 2" @click="updateStatus(item.job.id)" style="background-color: #FFDA76; width: 110px; text-align: center; cursor: pointer;">Đang thực hiện <md-tooltip>Click để cập nhật hoàn thành</md-tooltip></md-chip>
                                        <md-chip v-else-if="item.jobVendor !== null" @click="updateStatus(item.job.id)" style="background-color: #AD49E1; width: 110px; text-align: center; cursor: pointer;">Điều xe ngoài</md-chip>
                                        <md-chip v-else style="background-color: #b2b2b2; width: 110px; text-align: center;">Chưa điều xe</md-chip>
                                    </td>
                                    <td class="center" style="z-index: 9995;">
                                        <md-chip v-if="item.jobTypeStatus == 1 || item.jobVendor !== null" style="background-color: #88D66C; width: 100px; text-align: center;">Tròn tua</md-chip>
                                        <md-chip v-else-if="item.jobType == 2 && item.job == null" style="background-color: #FFDE4D; width: 100px; text-align: center;">Đã hạ cont</md-chip>
                                        <md-chip v-else-if="item.jobTypeStatus == 2" style="background-color: #FFDE4D; width: 100px; text-align: center;">Hạ cont</md-chip>
                                        <md-chip v-else-if="item.jobType == 3 && item.job == null" style="background-color: #E68369; width: 100px; text-align: center;">Đã cắt mooc</md-chip>
                                        <md-chip v-else-if="item.jobTypeStatus == 3" style="background-color: #E68369; width: 100px; text-align: center;">Cắt mooc</md-chip>
                                        <md-chip v-else-if="item.jobTypeStatus == 4" style="background-color: #9CDBA6; width: 100px; text-align: center;">Trả rỗng</md-chip>
                                        <md-chip v-else-if="item.jobTypeStatus == 5" style="background-color: #96C9F4; width: 100px; text-align: center;">Trả hàng</md-chip>
                                        <md-chip v-else style="background-color: #b2b2b2; width: 100px; text-align: center;">Chưa điều xe</md-chip>
                                    </td>
                                    <td>
                                        <md-chip v-if="item.subDistributeId > 0 || item.jobVendor !== null" style="background-color: #FF8225; width: 100px; text-align: center;">Có Vendor</md-chip>
                                        <md-chip v-else style="background-color: #3FA2F6; width: 100px; text-align: center;">Không vendor</md-chip>
                                    </td>
                                    <td>
                                        <span v-if="item.job !== null">{{  item.job.jobDate }}</span>
                                        <span v-else-if="item.jobVendor !== null">{{  item.jobVendor.jobDate }}</span>
                                    </td>
                                    <td>{{ item.order.customer.companyAlias }}</td>
                                    <td>{{ getTypeOfTransport(item.typeOfTransport) }}</td>
                                    <td>{{ Math.round(item.kg/1000, 2) }}</td>
                                    <td>
                                        <span v-if="item.order.booking.noBooking !== ''">{{ item.order.booking.noBooking }}</span>
                                    </td>
                                    <td>{{ item.order.orderName }}</td>
                                    <td>{{ item.unit.unitName }}</td>
                                    <td>{{ item.serial }}</td>
                                    <td>{{ item.seal }}</td>
                                    <td>
                                        <span v-if="item.order.booking.cutoffTime !== ''">{{ item.order.booking.cutoffTime }}</span>
                                        <span style="padding-left: 2px;" v-if="item.order.booking.cutoffDate !== ''">{{ item.order.booking.cutoffDate }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.locations.length >= 1">
                                            {{ item.locations[0].locationName }}
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="item.locations.length >= 2">
                                            {{ item.locations[1].locationName }}
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="item.locations.length >= 3">
                                            {{ item.locations[2].locationName }}
                                        </span>
                                    </td>
                                    <td>
                                        <span v-if="item.job !== null">{{  item.job.vehicle.licensePlate }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.job !== null">{{  item.job.driver.fullName }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.job !== null">{{  item.job.driver.phone }}</span>
                                    </td>
                                    <td>
                                        <span v-if="item.job !== null && item.job.mooc !== null">{{ item.job.mooc.licensePlate }}</span>
                                    </td>
                                    <td>{{ item.order.staff.fullName }}</td>
                                    <td>{{ item.note }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="table-footer" v-if="pager.totalPages > 0">
                    <div class="grid">
                        <div class="row">
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(1)" :class="{ 'page-active': search.pageIndex == 1 }">Trang đầu</a>
                                    <a @click="onPage(search.pageIndex - 1)">Trang trước</a>
                                </div>
                            </div>
                            <div class="col l-6 m-6 c-12">
                                <div class="paging">
                                    <div class="total-page">
                                        <div>Page</div> 
                                        <div style="padding-left:10px;">
                                            <md-field>
                                                <md-select v-model="search.pageIndex">
                                                    <md-option v-for="page in pager.pages" :key="page" :value="page">{{page}}</md-option>
                                                </md-select>
                                            </md-field>
                                        </div>
                                        <div style="padding-left:10px;">of <b>{{pager.totalPages}}</b></div>
                                    </div>
                                    <div class="row-of-page">
                                        <div style="padding-right:10px;">Rows/page:</div>
                                        <md-field>
                                            <md-select v-model="search.pageSize">
                                                <md-option v-for="pageSize in rowPerPageOptions" :key="pageSize.id" :value="pageSize.id">{{pageSize.text}}</md-option>
                                            </md-select>
                                        </md-field>
                                    </div>
                                    <div class="record-of-page">
                                        <div style="padding-right:10px;">Xem:</div>
                                        <div><b>{{pager.startIndex}}</b> - <b>{{pager.endIndex}}</b>/<b>{{pager.totalItems}}</b> bản ghi</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col l-3 m-3 c-12">
                                <div class="move-page">
                                    <a @click="onPage(search.pageIndex + 1)">Trang sau</a>
                                    <a @click="onPage(pager.totalPages)" :class="{ 'page-active': search.pageIndex == pager.totalPages }">Trang cuối</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <customerList ref="customerList" title="khách hàng" :type="customerType" @close="closeCustomer"/>
        <contractList ref="contractList" @close="closeContract"/>
        <orderList ref="orderList" @close="closeOrder"/>
        <viewForm ref="viewForm" />
        <viewJobVendor ref="viewJobVendor" />
        <crudForm ref="crudForm" @refresh="refresh" />
        <crudVendor ref="crudVendor" @refresh="refresh"/>
        <viewAttachFile ref="viewAttachFile" />
    </div>
</template>

<script>
    import jobService from '../../../api/jobService';
    import branchService from '../../../api/branchService';
    import orderService from '../../../api/orderService';
    import contractService from '../../../api/contractService';
    import companyService from '../../../api/companyService';
    import staffService from '../../../api/staffService';
    import messageBox from '../../../utils/messageBox';
    import { mapActions } from 'vuex';
    import common from '../../../mixins';
    import customerList from '../../../components/popup/_CompanyList.vue';
    import contractList from '../../../components/popup/_ContractList.vue';
    import orderList from '../../../components/popup/_OrderList.vue';
    import crudForm from './Crud.vue';
    import viewForm from './View.vue';
    import crudVendor from './CrudVendor.vue';
    import viewJobVendor from './ViewJobVendor.vue';
    import viewAttachFile from './ViewAttachFile.vue';

    export default {
        components: {
            customerList,
            contractList,
            orderList,
            crudForm,
            viewForm,
            crudVendor,
            viewJobVendor,
            viewAttachFile
        },
        metaInfo: {
            title: 'Danh sách điều xe'
        },
        data() {
            return {
                closeOnClick: false,
                closeOnSelect: true,
                loadding: false,
                id: 0,
                planList: common.planOfTransport,
                customerType: common.companyType.customer,
                typeList: common.typeOfTransport,
                submitted: false,
                search: { pageIndex: 1, pageSize: 50, planId: 0, code: '', serial: '', status: 0, branchId: 0, orderId: 0, keyword: '', customerId: 0, fromDate: common.dateNow, toDate: this.getRangeDate(1) },
                pager: { totalPages: 1 },
                data: [],
                rowPerPageOptions: [],
                orderType: 1,
                customerName: '',
                customers: [],
                contractName: '',
                contracts: [],
                orderName: '',
                orders: [],
                checkedList: [],
                branchs: []
            }
        },
        created(){
            this.rowPerPageOptions = common.rowPerPageOptions;
            this.getBranchs();
        },
        methods: {
            ...mapActions('common', ['setLoading']),

            viewAttachFile(item){
                this.$refs.viewAttachFile.open(item.orderId, item.id, item.serial);
            },
            
            addJob(item){
                let selectedList = [];
                selectedList.push(item);
                this.$refs.crudForm.open(selectedList);
            },

            addVendor(item){
                this.$refs.crudVendor.open(item);
            },
            
            viewJob(jobId){
                this.$refs.viewForm.open(jobId);
            },

            editJob(obj){
                this.$refs.crudForm.update(obj.job.id);
            },

            editJobVendor(obj){
                this.$refs.crudVendor.update(obj.jobVendor.id, obj);
            },

            viewJobVendor(obj){
                this.$refs.viewJobVendor.open(obj.jobVendor.id);
            },

            updateStatus(jobId){
                this.setLoading(true);
                jobService.updateStatus(jobId, 2).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Cập nhật trạng thái thành công");
                        this.filter();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            btnSearch(){
                this.submitted = true;
                if(this.search.fromDate == null){
                    messageBox.showWarning("Vui lòng chọn từ ngày");
                    return;
                }
                else if(this.search.toDate == null){
                    messageBox.showWarning("Vui lòng chọn tới ngày");
                    return;
                }   
                this.filter();
            },

            getBranchs(){
                this.setLoading(true);
                branchService.getAll().then((response) => {
                    if(response.statusCode == 200){
                        this.branchs = response.data;
                        this.search.branchId = response.data[0].id;
                        this.filter();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            openJob(){
                if(this.checkedList.length == 0){
                    messageBox.showWarning("Vui lòng chọn cont/kiện hàng điều xe");
                    return;
                }
                let selectedList = [];
                for(let i = 0; i < this.checkedList.length; i++){
                    let selected = this.data.findIndex(x => x.id == this.checkedList[i]);
                    selectedList.push(this.data[selected]);
                }
                this.$refs.crudForm.open(selectedList);
            },

            refresh(){
                this.getData();
            },

            filter(){
                let url = 'page=' + this.search.pageIndex + '&pageSize=' + this.search.pageSize;
                this.$router.push('/order-vehicle?' + url).catch(()=>{});

                this.getData();
            },

            onPage(page){
                if(page > this.pager.totalPages){
                    return;
                }
                if(page == 0){
                    return;
                }
                this.search.pageIndex = page;
                this.getData();
            },

            getData(){
                this.loadding = true;
                orderService.getGoodsForOrderVehicel(this.search).then((response) => {
                    if(response.statusCode == 200){
                        this.data = response.data.items;
                        this.pager = response.data.pager;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.loadding = false; this.submitted = false; });
            },

            getByAccount(){
                this.setLoading(true);
                staffService.getByAccount().then((response) => {
                    if(response.statusCode == 200){
                        if(response.data != null){
                            this.submitDistributeId = response.data.id;
                        }
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },

            getLocation(item) {
                let location = [];
                if(item.placeOfReceiptCont !== null && item.placeOfReceiptCont !== undefined){
                    location.push(item.placeOfReceiptCont.locationName);
                }
                if(item.placeOfReceiptGoods !== null && item.placeOfReceiptGoods !== undefined){
                    location.push(item.placeOfReceiptGoods.locationName);
                }
                if(item.transitDelivery !== null && item.transitDelivery !== undefined){
                    location.push(item.transitDelivery.locationName);
                }
                if(item.transitReceipt !== null && item.transitReceipt !== undefined){
                    location.push(item.transitReceipt.locationName);
                }
                if(item.placeOfDeliveryGoods !== null && item.placeOfDeliveryGoods !== undefined){
                    location.push(item.placeOfDeliveryGoods.locationName);
                }
                if(item.placeOfDeliveryCont !== null && item.placeOfDeliveryCont !== undefined){
                    location.push(item.placeOfDeliveryCont.locationName);
                }

                if(location.length > 0){
                    return location.join([' - ']);
                }
                else{
                    return "";
                }
            },

            //Customer
            closeCustomer(item){
                this.customerName = item.companyAlias;
                this.search.customerId = item.id;
                this.$refs.customerList.close();
            },

            getCustomerSelected(val){
                this.search.customerId = val.id;
                this.customerName = val.companyAlias;
            },

            openCustomer(){
                this.$refs.customerList.open(7);
            },

            getCustomers(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, companyType: this.customerType, alias: searchTerm };
                companyService.getCompanies(search).then((response) => {
                    if(response.statusCode == 200){
                        this.customers = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Contract
            closeContract(item){
                this.contractName = item.contractCode;
                this.search.contractId = item.id;
                this.$refs.contractList.close();
            },

            getContractSelected(val){
                this.search.contractId = val.id;
                this.contractName = val.contractCode;
            },

            openContract(){
                this.$refs.contractList.open();
            },

            getContracts(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, code: searchTerm };
                contractService.getContracts(search).then((response) => {
                    if(response.statusCode == 200){
                        this.contracts = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            //Order
            closeOrder(item){
                this.orderName = item.orderCode;
                this.search.orderId = item.id;
                this.$refs.orderList.close();
            },

            getOrderSelected(val){
                this.search.orderId = val.id;
                this.orderName = val.orderCode;
            },

            openOrder(){
                this.$refs.orderList.open();
            },

            getOrders(searchTerm){
                let search = { pageIndex: 1, pageSize: 30, orderType: 1, contractIds: '', customerIds: '', ownerIds: '', code: searchTerm };
                orderService.getOrders(search).then((response) => {
                    if(response.statusCode == 200){
                        this.orders = response.data.items;
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally();
            },

            confirmDelete(id){
                this.$modal.show('dialog', {
                title: 'Thông báo',
                text: 'Bạn có chắc muốn xóa không?',
                buttons: [
                        {
                            title: 'Hủy',
                            handler: () => {
                                this.$modal.hide('dialog')
                            }
                        },
                        {
                            title: 'Xóa',
                            handler: () => {
                                this.del(id)
                            }
                       }
                ]
                })
            },

            del(id){
                this.$modal.hide('dialog');
                this.setLoading(true);
                jobService.delete(id).then((response) => {
                    if(response.statusCode == 200){
                        messageBox.showMessage("Xóa thành công");
                        this.refresh();
                    }
                    else{
                        messageBox.showWarning(response.data);
                    }
                }).finally(() => { this.setLoading(false); });
            },
        },
        watch: {
            orderName: function (val) { 
                if(val == ''){
                    this.search.orderId = 0;
                }
            },
            customerName: function (val) { 
                if(val == ''){
                    this.search.customerId = 0;
                }
            },
            contractName: function (val) { 
                if(val == ''){
                    this.search.contractId = 0;
                }
            },
            'search.pageIndex': function () {
                this.filter();
            },
            'search.pageSize': function () {
                this.search.pageIndex = 1;
                this.filter();
            }
        }
    }
</script>

<style scoped>

    ::-webkit-scrollbar{
        height: 10px;
        width: 0;
    }
    ::-webkit-scrollbar-thumb:horizontal{
        background: #888;
        border-radius: 10px;
    }
    
    .table-scroll {
        position: relative;
        z-index:1;
        margin: auto;
        overflow-y:scroll;
        height: calc(100vh - 220px);
    }
    .table-scroll table {
        width: 100%;
        margin: auto;
        border-collapse: separate;
        border-spacing: 0;
        display: contents;
    }
    .table-wrap {
        position:relative;
    }
    .table-scroll th, .table-scroll td {
        padding: 5px 10px;
        background: #fff;
        vertical-align: middle;
    }
    .table-scroll tr td{
        border-top: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll tr th {
        border-bottom: 1px solid #e0e0e0;
        border-left: 1px solid #e0e0e0;
        border-right: 0;
        border-bottom: 0;
    }
    .table-scroll td:last-child, .table-scroll th:last-child {
        border-right: 1px solid #e0e0e0;
    }
    .table-scroll thead tr:last-child th {
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody tr:last-child td {
        text-align:left;
        border-bottom: 1px solid #e0e0e0;
    }
    .table-scroll tbody th {
        text-align:left;
    }
    .table-scroll thead tr:first-child th {
        position: -webkit-sticky;
        position:sticky;
        top: 0;
        height: 30px;
    }
    .table-scroll thead tr:nth-child(2) th {
        color:#333;
        position: -webkit-sticky;
        position:sticky;
        top: 45px;
    }
    /* safari and ios need the tfoot itself to be position:sticky also */
    .table-scroll tfoot, .table-scroll tfoot th, .table-scroll tfoot td {
        position:-webkit-sticky;
        position:sticky;
        bottom:0;
        background:#666;
        color:#fff;
        text-align:center;
    }
    a:focus {
        background:red
    }/* testing links*/

</style>