<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9998;">
        <md-content style="width: 1200px;">
            <div class="page-content-1" style="height: 95vh; overflow: auto">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action">
                                <md-button v-if="!entity.isInvoice" v-shortkey="['ctrl', 'l']" @shortkey="submit()" @click="submit()" class="md-raised md-primary"><span>L</span>ưu<md-tooltip>Lưu (Ctrl + L)</md-tooltip></md-button>
                                <md-button v-shortkey="['ctrl', 'i']" @shortkey="close()" @click="close()" class="md-raised">Đóng</md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body" style="margin-bottom: 20px;">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div class="group-box" style="margin-top:10px;">
                                        <div class="group-title">THÔNG TIN ĐIỀU XE</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field :class="{'md-invalid': submitted && $v.entity.jobCode.$error }">
                                                        <label for="jobCode">Mã phiếu<span class="label-require">(*)</span></label>
                                                        <md-input disabled name="jobCode" v-model="entity.jobCode"></md-input>
                                                        <span class="md-error" v-if="submitted && !$v.entity.jobCode.required">Vui lòng nhập mã phiếu</span>
                                                    </md-field>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-datepicker v-model="entity.submitDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.submitDate.$error }">
                                                        <label>Ngày yêu cầu<span class="label-require">(*)</span></label>
                                                        <span class="md-error" v-if="submitted && !$v.entity.submitDate.required">Vui lòng chọn ngày yêu cầu</span>
                                                    </md-datepicker>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-datepicker v-model="entity.jobDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.jobDate.$error }">
                                                        <label>Ngày thực hiện<span class="label-require">(*)</span></label>
                                                        <span class="md-error" v-if="submitted && !$v.entity.jobDate.required">Vui lòng chọn ngày thực hiện</span>
                                                    </md-datepicker>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="licensePlate" @md-selected="getVehicleSelected" :md-options="vehicles" @md-changed="getVehicles"  @md-opened="getVehicles" :class="{'md-invalid': submitted && $v.entity.vehicleId.$error }">
                                                            <label>Số xe<span class="label-require">(*)</span></label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                            <span class="md-error" v-if="submitted && !$v.entity.vehicleId.isSelected">Vui lòng chọn xe</span>
                                                        </md-autocomplete>
                                                        <md-button @click="openVehicle()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm xe</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="driverName" @md-selected="getDriverSelected" :md-options="drivers" @md-changed="getDrivers"  @md-opened="getDrivers" :class="{'md-invalid': submitted && $v.entity.driverId.$error }">
                                                            <label>Lái xe<span class="label-require">(*)</span></label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                            <span class="md-error" v-if="submitted && !$v.entity.driverId.isSelected">Vui lòng chọn lái xe</span>
                                                        </md-autocomplete>
                                                        <md-button @click="openDriver()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm lái xe</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="moocCode" @md-selected="getMoocSelected" :md-options="moocs" @md-changed="getMoocs"  @md-opened="getMoocs" :class="{'md-invalid': submitted && $v.entity.moocId.$error }">
                                                            <label>Số mooc<span class="label-require">(*)</span></label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.licensePlate }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                            <span class="md-error" v-if="submitted && !$v.entity.moocId.isSelected">Vui lòng chọn mooc</span>
                                                        </md-autocomplete>
                                                        <md-button @click="openMooc()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm mooc</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                                            <label>Nhân viên điều xe<span class="label-require">(*)</span></label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                                        </md-autocomplete>
                                                        <md-button @click="openStaff()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="startName" @md-selected="getStartSelected" :md-options="startList" @md-changed="getStartList"  @md-opened="getStartList">
                                                            <label>Điểm xuất phát</label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                        </md-autocomplete>
                                                        <md-button @click="openStart()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                        </md-button>
                                                    </div>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="endName" @md-selected="getEndSelected" :md-options="endList" @md-changed="getEndList"  @md-opened="getEndList">
                                                            <label>Điểm kết thúc</label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.locationName }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                        </md-autocomplete>
                                                        <md-button @click="openEnd()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                        </md-button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="checkbox-group">
                                                        <div class="form-control">
                                                            <md-checkbox v-model="entity.isJob">Tính chuyến</md-checkbox>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field>
                                                        <label for="note">Ghi chú</label>
                                                        <md-input name="note" v-model="entity.note"></md-input>
                                                    </md-field>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="radio-group">
                                                        <label>Trạng thái</label>
                                                        <div class="form-control">
                                                            <md-radio v-model="entity.status" :value="1">Chưa hoàn thành</md-radio>
                                                            <md-radio v-model="entity.status" :value="2">Hoàn thành</md-radio>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="padding-top: 10px;">
                                <div class="col l-12 m-12 c-12">
                                    <div v-if="action !== 'explain'" style="margin: 7px 0;">
                                        <a @click="openOrderDetail()" class="btn-add"><md-icon>add</md-icon> Thêm cont/kiện hàng<md-tooltip>Thêm dòng</md-tooltip></a>
                                    </div>
                                    <div class="order-detail-list">
                                        <div class="tab">
                                            <a :class="{'tab-active': tab == item.id || entity.details.length == 1 }" @click="onTab(item.id)" v-for="(item, index) in entity.details" :key="'tab-' + index">
                                                {{ 'Kiện hàng - ' + (index + 1) }}
                                                <i v-if="entity.details.length > 1" @click="delDetail(item.id)" class="md-icon md-icon-font md-theme-default">clear</i>
                                            </a>
                                        </div>
                                        <div v-show="tab == item.id || entity.details.length == 1"  v-for="(item, index) in entity.details" :key="'tab-content-' + index" class="tab-content">
                                            <div v-if="loadingTab == true" style="height: 100px; padding-top: 30px;">
                                                <md-progress-bar md-mode="indeterminate"></md-progress-bar>
                                            </div>
                                            <div v-else-if="loadingTab == false">
                                                <div class="row">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="group-box" style="margin-top:5px; padding: 20px 10px 10px 20px;">
                                                            <div class="group-title">THIẾT LẬP ĐIỀU XE</div>
                                                            <div class="row">
                                                                <div class="col l-12 m-12 c-12">
                                                                    <div class="radio-group">
                                                                        <div v-if="action == 'add'" class="form-control">
                                                                            <md-radio v-if="item.numJob == 0" v-model="item.jobType" :value="1">Tròn tua</md-radio>
                                                                            <md-radio v-if="item.numJob == 0" v-model="item.jobType" :value="2">Hạ cont</md-radio>
                                                                            <md-radio v-if="item.numJob == 0" v-model="item.jobType" :value="3">Cắt mooc</md-radio>
                                                                            <md-radio v-model="item.jobType" :value="4">Trả rỗng</md-radio>
                                                                            <md-radio v-model="item.jobType" :value="5">Trả hàng</md-radio>
                                                                        </div>
                                                                        <div v-else-if="action == 'edit'" class="form-control">
                                                                            <md-radio v-if="item.jobType < 4" v-model="item.jobType" :value="1">Tròn tua</md-radio>
                                                                            <md-radio v-if="item.jobType < 4" v-model="item.jobType" :value="2">Hạ cont</md-radio>
                                                                            <md-radio v-if="item.jobType < 4" v-model="item.jobType" :value="3">Cắt mooc</md-radio>
                                                                            <md-radio v-model="item.jobType" :value="4">Trả rỗng</md-radio>
                                                                            <md-radio v-model="item.jobType" :value="5">Trả hàng</md-radio>
                                                                        </div>
                                                                        <div v-else class="form-control">
                                                                            <md-radio v-model="item.jobType" :value="1">Tròn tua</md-radio>
                                                                            <md-radio v-model="item.jobType" :value="2">Hạ cont</md-radio>
                                                                            <md-radio v-model="item.jobType" :value="3">Cắt mooc</md-radio>
                                                                            <md-radio v-model="item.jobType" :value="4">Trả rỗng</md-radio>
                                                                            <md-radio v-model="item.jobType" :value="5">Trả hàng</md-radio>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col l-7 m-7 c-12">
                                                        <div class="group-box" style="margin-top:15px; padding: 20px 10px 5px 20px;">
                                                            <div class="group-title">CẬP NHẬT GIAO HÀNG</div>
                                                            <div class="row">
                                                                <div class="col l-12 m-12 c-12">
                                                                    <div class="form-control">
                                                                        <md-radio v-model="item.status" :value="1">Chưa giao/nhận hàng</md-radio>
                                                                        <md-radio v-model="item.status" :value="2">Đã giao/nhận hàng</md-radio>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col l-5 m-5 c-12">
                                                        <div class="group-box" style="margin-top:10px; padding: 20px 10px 5px 20px;">
                                                            <div class="group-title">FILE ĐÍNH KÈM</div>
                                                            <div class="row">
                                                                <div class="col l-12 m-12 c-12" style="padding-bottom: 10px;">
                                                                    <md-button @click="viewAttachFile(item)" class="md-raised md-primary">
                                                                        Xem 
                                                                        <md-tooltip>Click để xem file đính kèm</md-tooltip>
                                                                    </md-button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="group-box" style="margin-top:20px;">
                                                            <div class="group-title">THÔNG TIN HÀNG HÓA</div>
                                                            <div class="row">
                                                                <div class="col l-4 m-4 c-12 box-1">
                                                                    <div class="box-content">
                                                                        <div class="box-item">
                                                                            <div>Khách hàng:</div>
                                                                            <div> 
                                                                                {{ item.orderDetail.order.customer.companyAlias}}
                                                                            </div>
                                                                        </div>
                                                                        <div class="box-item">
                                                                            <div>Loại hình:</div>
                                                                            <div>{{ getTypeOfTransport(item.orderDetail.typeOfTransport) }}</div>
                                                                        </div>
                                                                        <div class="box-item">
                                                                            <div>Mặt hàng:</div>
                                                                            <div>
                                                                                {{ item.orderDetail.goods.goodsName }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="box-item">
                                                                            <div>Khối lượng(tấn):</div>
                                                                            <div> 
                                                                                {{ Math.round(item.orderDetail.kg/1000) }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col l-3 m-3 c-12 box-2">
                                                                    <div class="box-content">
                                                                        <div class="box-item">
                                                                            <div>Bill/Booking:</div>
                                                                            <div>
                                                                                {{ item.orderDetail.order.booking.noBooking }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="box-item">
                                                                            <div>Số cont:</div>
                                                                            <div v-if="isSerialEdit == false" class="editable">
                                                                                <span>{{ item.orderDetail.serial }}</span>
                                                                                <a @click="isSerialEdit = true">Sửa</a>
                                                                            </div>
                                                                            <div v-else>
                                                                                <input style="width: 130px;" class="input-2" type="text" v-model="item.orderDetail.serial" />
                                                                            </div>
                                                                        </div>
                                                                        <div class="box-item">
                                                                            <div>Loại cont:</div>
                                                                            <div>
                                                                                {{ item.orderDetail.unit.unitName }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="box-item">
                                                                            <div>Số seal:</div>
                                                                            <div v-if="isSealEdit == false" class="editable">
                                                                                <span>{{ item.orderDetail.seal }}</span>
                                                                                <a @click="isSealEdit = true">Sửa</a>
                                                                            </div>
                                                                            <div v-else>
                                                                                <input style="width: 130px;" class="input-2" type="text" v-model="item.orderDetail.seal" />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col l-5 m-5 c-12 box-3">
                                                                    <div class="box-item">
                                                                        <div>Closing time:</div>
                                                                        <div>
                                                                            <span v-if="item.orderDetail.order.booking.cutoffTime !== ''">
                                                                                {{ item.orderDetail.order.booking.cutoffTime }}
                                                                            </span>
                                                                            <span v-if="item.orderDetail.order.booking.cutoffDate !== ''">
                                                                                {{ item.orderDetail.order.booking.cutoffDate }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="box-item">
                                                                        <div>Nơi nhận cont:</div>
                                                                        <div>
                                                                            <span v-if="item.orderDetail.typeOfTransport == 1 && item.orderDetail.transitDeliveryId > 0">
                                                                                {{ item.orderDetail.transitDelivery.locationName }}
                                                                            </span>
                                                                            <span v-else-if="item.orderDetail.typeOfTransport == 2 && item.orderDetail.transitReceiptId > 0">
                                                                                {{ item.orderDetail.transitReceipt.locationName }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="box-item">
                                                                        <div>Nơi đóng/rút hàng:</div>
                                                                        <div>
                                                                            <span v-if="item.orderDetail.typeOfTransport == 1 && item.orderDetail.placeOfReceiptGoodsId > 0">
                                                                                {{ item.orderDetail.placeOfReceiptGoods.locationName }}
                                                                            </span>
                                                                            <span v-else-if="item.orderDetail.typeOfTransport == 2 && item.orderDetail.placeOfDeliveryGoodsId > 0">
                                                                                {{ item.orderDetail.placeOfDeliveryGoods.locationName }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="box-item">
                                                                        <div>Nơi trả cont:</div>
                                                                        <div>
                                                                            <span v-if="item.orderDetail.typeOfTransport == 1 && item.orderDetail.placeOfReceiptContId > 0">
                                                                                {{ item.orderDetail.placeOfReceiptCont.locationName }}
                                                                            </span>
                                                                            <span v-else-if="item.orderDetail.typeOfTransport == 2 && item.orderDetail.placeOfDeliveryContId > 0">
                                                                                {{ item.orderDetail.placeOfDeliveryCont.locationName }}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-if="action == 'add' || action == 'edit'" class="row" style="padding-top: 10px;">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="group-box" style="margin-top:10px; padding-bottom: 0;">
                                                            <div class="group-title">DỰ TOÁN CHI PHÍ</div>
                                                            <div class="box-content">
                                                                <div class="row">
                                                                    <div class="col l-12 m-12 c-12">
                                                                        <div class="tool-bar" style="margin-top: 5px; padding-bottom: 0;">
                                                                            
                                                                        </div>
                                                                        <table class="data-table-2"> 
                                                                            <thead> 
                                                                                <tr> 
                                                                                    <th style="width:30px;">STT</th>
                                                                                    <th style="width:35%;">Tên phí</th> 
                                                                                    <th style="width:15%;">Loại</th> 
                                                                                    <th style="width:20%;">Số tiền</th>
                                                                                    <th style="width:25%;">Ghi chú</th>
                                                                                </tr> 
                                                                            </thead> 
                                                                            <tbody v-if="loading == true" style="height: 100px;"> 
                                                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                            </tbody>
                                                                            <tbody v-if="loading == false"> 
                                                                                <tr v-for="(obj, ind) in item.estimate.details" :key="'est-' + ind" :class="{ 'odd': ind % 2 !== 0 }"> 
                                                                                    <td class="center">{{ ind + 1 }}</td>
                                                                                    <td class="left">
                                                                                        {{obj.fee.feeName}}
                                                                                    </td>
                                                                                    <td class="center">
                                                                                        <md-field>
                                                                                            <md-select v-model="obj.type" name="type" id="type">
                                                                                                <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                            </md-select>
                                                                                        </md-field>
                                                                                    </td>
                                                                                    <td class="center">
                                                                                        <div class="group-control">
                                                                                            <number v-model="obj.estimateAmount" v-bind="currencyF" class="form-control currency"></number> 
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="center">
                                                                                        <div class="group-control">
                                                                                            <input v-model="obj.estimateNote" class="form-control" type="text">
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody> 
                                                                        </table> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div v-else-if="action == 'explain'" class="row" style="padding-top: 10px;">
                                                    <div class="col l-12 m-12 c-12">
                                                        <div class="group-box" style="margin-top:10px; padding-bottom: 0;">
                                                            <div class="group-title">THANH TOÁN CHI PHÍ</div>
                                                            <div class="box-content">
                                                                <div class="row">
                                                                    <div class="col l-12 m-12 c-12">
                                                                        <div class="tool-bar" style="margin-top: 5px; padding-bottom: 0;">
                                                                            
                                                                        </div>
                                                                        <table class="data-table-2"> 
                                                                            <thead> 
                                                                                <tr> 
                                                                                    <th style="width:30px;">Stt</th>
                                                                                    <th style="width:19%;">Tên phí</th> 
                                                                                    <th style="width:11%;">Loại</th> 
                                                                                    <th style="width:9%;">Dự toán</th>
                                                                                    <th style="width:10%;">Đơn giá giải chi</th>
                                                                                    <th style="width:9%;">VAT</th>
                                                                                    <th style="width:9%;">Thành tiền giải chi</th>
                                                                                    <th style="width:10%;">Hóa đơn</th>
                                                                                    <th style="width:13%;">Ngày HĐ</th>
                                                                                    <th style="width:15%;">Ghi chú</th>
                                                                                </tr> 
                                                                            </thead> 
                                                                            <tbody v-if="loading == true" style="height: 100px;"> 
                                                                                <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                                            </tbody>
                                                                            <tbody v-if="loading == false"> 
                                                                                <tr v-for="(obj, ind) in item.estimate.details" :key="'est-' + ind" :class="{ 'odd': ind % 2 !== 0 }"> 
                                                                                    <td class="center">
                                                                                        {{ ind + 1 }}
                                                                                    </td>
                                                                                    <td class="left">
                                                                                        {{obj.fee.feeName}}
                                                                                    </td> 
                                                                                    <td class="center">
                                                                                        <md-field>
                                                                                            <md-select v-model="obj.type" name="type" id="type">
                                                                                                <md-option v-for="item in types" :key="'type' + item.value" :value="item.value">{{item.text}}</md-option>
                                                                                            </md-select>
                                                                                        </md-field>
                                                                                    </td>
                                                                                    <td class="right" style="padding-right: 10px;">
                                                                                        {{ formatNumber(obj.estimateAmount) }}
                                                                                    </td>
                                                                                    <td class="center">
                                                                                        <div class="group-control">
                                                                                            <number @change="calSumExplain(index, obj)" v-model="obj.explainPrice" v-bind="currencyF" class="form-control currency"></number> 
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="center">
                                                                                        <div class="group-control">
                                                                                            <number @change="calSumExplain(index, obj)" v-model="obj.explainVat" v-bind="vatF" class="form-control currency"></number> 
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="right" style="padding-right: 10px;">
                                                                                        {{ formatNumber(obj.explainAmount) }}
                                                                                    </td>
                                                                                    <td class="center">
                                                                                        <div class="group-control">
                                                                                            <input v-model="obj.invoiceNumber" class="form-control" type="text">
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="center">
                                                                                        <div class="group-control">
                                                                                            <md-datepicker v-model="obj.invoiceDate" md-immediately :md-model-type="String"></md-datepicker>
                                                                                        </div>
                                                                                    </td>
                                                                                    <td class="center">
                                                                                        <div class="group-control">
                                                                                            <input v-model="obj.explainNote" class="form-control" type="text">
                                                                                        </div>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody> 
                                                                        </table> 
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row" style="padding-top: 10px;">
                                <div class="col l-12 m-12 c-12">
                                    <div class="group-box" style="margin-top:10px; padding-bottom: 0;">
                                        <div class="group-title">THÔNG TIN HÀNH TRÌNH</div>
                                        <div class="box-content">
                                            <div class="tool-bar" style="margin-top: 5px; padding-bottom: 0;">
                                                <md-button @click="addRoute()" class="md-primary"><md-icon>add_card</md-icon> Thêm dòng<md-tooltip>Thêm dòng</md-tooltip></md-button>
                                            </div>
                                            <table class="data-table-2"> 
                                                <thead> 
                                                    <tr> 
                                                        <th style="width:100px;">Hành động</th>
                                                        <th style="width:120px;">Hành trình</th>
                                                        <th style="width:20%;">Điểm đi<span class="label-require">(*)</span></th> 
                                                        <th style="width:20%;">Điểm đến<span class="label-require">(*)</span></th> 
                                                        <th style="width:10%;">Số Km<span class="label-require">(*)</span></th>
                                                        <th style="width:10%;">Định mức dầu(lít)<span class="label-require">(*)</span></th>
                                                        <th style="width:12%;">Đơn giá<span class="label-require">(*)</span></th>
                                                        <th style="width:13%;">Thành tiền</th>
                                                    </tr> 
                                                </thead> 
                                                <tbody v-if="loading == true" style="height: 50px;"> 
                                                    <tr><td colspan="100"><md-progress-bar md-mode="indeterminate"></md-progress-bar></td></tr>
                                                </tbody>
                                                <tbody v-if="loading == false"> 
                                                    <tr v-for="(item, index) in entity.routes" :key="'route-' + index" :class="{ 'odd': index % 2 !== 0 }"> 
                                                        <td class="grid-action">
                                                            <a @click="delRoute(item)"><md-icon>clear</md-icon><md-tooltip>Xóa dòng</md-tooltip></a>
                                                        </td>
                                                        <td class="center">
                                                            {{ (index + 1) }}
                                                        </td>
                                                        <td class="center">
                                                            <div class="grid-control">
                                                                <input v-model="item.start.locationName" class="form-control" type="text">
                                                                <md-button @click="openStartLo(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm địa điểm</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                        </td> 
                                                        <td class="center">
                                                            <div class="grid-control">
                                                                <input v-model="item.end.locationName" class="form-control" type="text">
                                                                <md-button @click="openEndLo(item.id)" class="md-icon-button">
                                                                    <md-icon>manage_search</md-icon>
                                                                    <md-tooltip>Tìm địa điểm</md-tooltip>
                                                                </md-button>
                                                            </div>
                                                        </td>
                                                        <td class="center">
                                                            <div class="group-control">
                                                                <number  @change="calFuel(item)" v-model="item.km" :class="{'is-error': $v.entity.routes.$each[index].km.$error}" class="form-control currency"></number> 
                                                            </div>
                                                        </td>
                                                        <td class="center">
                                                            <div class="group-control">
                                                                <number  @change="calFuel(item)" v-model="item.fuel" :class="{'is-error': $v.entity.routes.$each[index].fuel.$error}" class="form-control currency"></number> 
                                                            </div>
                                                        </td>
                                                        <td class="center">
                                                            <div class="group-control">
                                                                <number @change="calFuel(item)" v-model="item.price" :class="{'is-error': $v.entity.routes.$each[index].price.$error}" class="form-control currency"></number> 
                                                            </div>
                                                        </td>
                                                        <td class="center">
                                                            <div class="group-control">
                                                                <number disabled v-model="item.amount" class="form-control currency"></number> 
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </tbody> 
                                            </table> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </md-content>
        <feeList ref="feeList" title="Phí làm hàng" @close="closeFee"/>
        <staffList ref="staffList" @close="closeStaff"/>
        <driverList ref="driverList" @close="closeDriver"/>
        <vehicleList ref="vehicleList" @close="closeVehicle"/>
        <locationStart ref="locationStart" @close="closeStart"/>
        <locationEnd ref="locationEnd" @close="closeEnd"/>
        <moocList ref="moocList" @close="closeMooc"/>
        <startList ref="startList" @close="closeStartLo"/>
        <endList ref="endList" @close="closeEndLo"/>
        <orderDetailList ref="orderDetailList" @close="closeOrderDetail"/>
        <viewAttachFile ref="viewAttachFile" />
    </md-dialog>
</template>

<script>
import locationService from '../../../api/locationService';
import jobService from "../../../api/jobService";
import staffService from "../../../api/staffService"
import driverService from '../../../api/driverService';
import vehicleService from '../../../api/vehicleService';
import staffList from '../../../components/popup/_StaffList.vue';
import driverList from '../../../components/popup/_DriverList.vue';
import vehicleList from '../../../components/popup/_VehicleList.vue';
import moocList from '../../../components/popup/_VehicleList.vue';
import { mapActions } from 'vuex';
import common from '../../../mixins';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import settingJobFeeService from '../../../api/settingJobFeeService';
import feeList from '../../../components/popup/_FeeList.vue';
import locationStart from '../../../components/popup/_LocationList.vue';
import locationEnd from '../../../components/popup/_LocationList.vue';
import startList from '../../../components/popup/_LocationList.vue';
import endList from '../../../components/popup/_LocationList.vue';
import orderDetailList from '../../../components/popup/_OrderDetailList.vue';
import viewAttachFile from './ViewAttachFile.vue';

export default ({
    components: {
        viewAttachFile,
        startList,
        endList,
        driverList,
        vehicleList,
        staffList,
        feeList,
        locationStart,
        locationEnd,
        moocList,
        orderDetailList
    },
    data() {
        return {
            loadingTab: false,
            isSerialEdit: false,
            isSealEdit: false,
            tab: 0,
            selectedId: 0,
            index: -2,
            loading: false,
            showDialog: false,
            title: "Lệnh điều xe",
            id: 0,
            driverName: '',
            drivers: [],
            licensePlate: '',
            vehicles: [],
            types: common.typeFee,
            submitted: false,
            entity: { routes: [], vehicleId: 0, isJob: true, staffId: 0, deptId: 0, staff: { fullName: '' }, jobCode: '', jobNumber: 0, jobDate: common.dateNow, submitDate: common.dateNow, driverId: 0, vehiclieId: 0, moocId: 0, details: [], note: '' },
            typeList: common.typeOfTransport,
            currencyF: common.currencyF2,
            vatF: common.vatF,
            staffName: '',
            staffs: [],
            startName: '',
            startList: [],
            endName: '',
            endList: [],
            moocCode: '',
            moocs: [],
            routes: [],
            action: 'add',
            jobType: 0
        }
    },
    created(){
        
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        viewAttachFile(item){
            this.$refs.viewAttachFile.open(item.orderId, item.orderDetailId, item.orderDetail.serial);
        },

        calFuel(item){
            let selected = this.entity.routes.findIndex(x => x.startId == item.startId && x.endId == item.endId);
            this.entity.routes[selected].amount = Math.round(parseFloat(this.entity.routes[selected].fuel) *  parseFloat(this.entity.routes[selected].price));
        },

        //Thêm Kiện Hàng
        async closeOrderDetail(item){
            let orderId = item.orderId;
            let orderDetailId = item.id;
            let arrayJobType = [1,2,3,4,5];
            const jobType = arrayJobType.filter(x => x != item.jobType)
            let obj = { 
                id: this.uuidv4(10000),
                jobId: 0,
                orderId: orderId,
                orderDetailId: orderDetailId,
                orderDetail: item,
                jobType: jobType[0],
                status: 1,
                numJob: item.numJob,
                estimate: { orderId: orderId, orderDetailId: orderDetailId, details: []}
            };
            if(item.numJob > 0){
                obj.jobType = 4;
            }
            else{
                obj.jobType = 1;
            }
            let response = await settingJobFeeService.getSetting(orderId, item.typeOfTransport, item.goodsTypeId, item.unitId);
            if(response.statusCode == 200){
                obj.estimate.orderId =  orderId;
                obj.estimate.orderDetailId = orderDetailId;
                obj.estimate.details = [];
                response.data.map((o) => {
                    let item = {
                        id: this.uuidv4(2000),
                        jobId: 0,
                        orderId: orderId,
                        orderDetailId: orderDetailId,
                        feeId: o.feeId,
                        fee: o.fee,
                        type: o.type,
                        estimateAmount: o.amount
                    };
                    obj.estimate.details.push(item);
                })
            }

            this.entity.details.push(obj);
            this.$refs.orderDetailList.close();
        },

        openOrderDetail(){
            this.$refs.orderDetailList.open();
        },

        delDetail(id){
            const index = this.entity.details.findIndex(x => x.id == id);
            this.entity.details.splice(index, 1);
            this.tab = this.entity.details[0].id;
        },

        async onTab(id){
            this.loadingTab = true;
            this.tab = id;
            await new Promise(resolve => setTimeout(resolve, 300));
            this.loadingTab = false;
        },

        addRoute(){
            let item = {
                id: this.uuidv4(this.entity.routes.length + 1),
                startId: 0,
                start: { locationName: '' },
                endId: 0,
                end: { locationName: '' },
                km: 0, 
                fuel: 0
            };
            
            this.entity.routes.push(item);
        },

        delRoute(obj){
            if(obj.id == 0){
                const index = this.entity.routes.findIndex(x => x.startId == obj.startId && x.endId == obj.endId);
                this.entity.routes.splice(index, 1);
            }
            else{
                const index = this.entity.routes.findIndex(x => x.id == obj.id);
                this.entity.routes.splice(index, 1);
            }
        },

        calSumExplain(idx, obj){
            const selected = this.entity.details[idx].estimate.details.findIndex(x => x.feeId == obj.feeId);
            let price = parseFloat(this.entity.details[idx].estimate.details[selected].explainPrice);
            let vat =  parseFloat(this.entity.details[idx].estimate.details[selected].explainVat) / 100;
            this.entity.details[idx].estimate.details[selected].explainAmount = price * (1 + vat);
        },

        addEstimateRow(idx){
            let item = {
                jobId: 0,
                feeId: 0,
                orderId: this.entity.details[idx].orderDetail.orderId,
                orderDetailId: this.entity.details[idx].orderDetail.id,
                fee: {feeCode: '', feeName: ''},
                type: 2,
                estimateAmount: 0
            };
            this.entity.details[idx].estimate.details.push(item);
        },

        delEstimateRow(idx, obj){
            const index = this.entity.details[idx].estimate.details.findIndex(x => x.id == obj.id);
            this.entity.details[idx].estimate.details.splice(index, 1);
        },

        update(jobId, action = 'edit'){
            this.action = action;
            this.id = jobId;
            this.getById();
            this.showDialog = true;
        },

        async open(list){
            this.isSealEdit = false;
            this.isSerialEdit = false;
            this.id = 0;
            this.licensePlate = '';
            this.moocCode = '';
            this.startName = '';
            this.endName = '';
            this.driverName = '';
            let i = 1;
            this.submitted = false;
            this.entity = { status: 1, routes: [], isJob: true, branchId: 0, vehicleId: 0, staffId: 0, deptId: 0, staff: { fullName: '' }, jobCode: '', jobNumber: 0, submitDate: common.dateNow, jobDate: common.dateNow, driverId: 0, vehiclieId: 0, moocId: 0, details: [] };
            list.map((item) => {
                this.jobType = item.jobType;
                let obj = { 
                    id: this.uuidv4(i),
                    jobId: 0,
                    orderId: item.orderId,
                    orderDetailId: item.id,
                    orderDetail: item,
                    estimate: { orderId: 0, orderDetailId: 0, details: []},
                    jobType: 1,
                    status: 1,
                    numJob: item.numJob
                };
                if(item.numJob > 0){
                    obj.jobType = 4;
                }
                else{
                    obj.jobType = 1;
                }
                if(i == 1){
                    this.tab = obj.id;
                }
                i++;
                
                this.entity.details.push(obj);
            });
            this.getByAccount();
            this.getRoutes();
            for(let i = 0; i < this.entity.details.length; i++){
                this.entity.branchId = this.entity.details[i].orderDetail.order.branchId;
                let orderId =  this.entity.details[i].orderDetail.orderId;
                let orderDetailId =  this.entity.details[i].orderDetail.id;
                let response = await settingJobFeeService.getSetting(orderId, list[i].typeOfTransport, list[i].goodsTypeId, list[i].unitId);
                if(response.statusCode == 200){
                    this.entity.details[i].estimate.orderId =  orderId;
                    this.entity.details[i].estimate.orderDetailId =  orderDetailId;
                    this.entity.details[i].estimate.details = [];
                    response.data.map((obj) => {
                        let item = {
                            id: this.uuidv4(i),
                            jobId: 0,
                            orderId: orderId,
                            orderDetailId: orderDetailId,
                            feeId: obj.feeId,
                            fee: obj.fee,
                            type: obj.type,
                            estimateAmount: obj.amount
                        };
                        this.entity.details[i].estimate.details.push(item);
                    })
                }
            }
            this.showDialog = true;
        },

        getRoutes(){
            this.setLoading(true);
            let job = JSON.parse(JSON.stringify(this.entity));

            if(job.details.length > 0){
                job.details.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                    self[index].estimate = null;
                });
            }
            job.routes = this.entity.routes;
            job.routes.map(item => {
                if(item.price == ""){
                    item.price = 0;
                    item.amount = 0;
                }
            });

            jobService.getRoutes(job).then((response) => {
                if(response.statusCode == 200){
                    this.entity.routes = response.data;
                }
            }).finally(() => { this.setLoading(false);  });
        },

        uuidv4(id) {
            let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
            );

            return uuid + "-" + id;
        },

        close(){
            this.isSealEdit = false;
            this.isSerialEdit = false;
            this.showDialog = false;
        },

        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.entity.details.length > 0){
                this.entity.details = this.entity.details.filter(item => !(item.id == 0));
                this.entity.details.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                    if(item.estimate.details.length > 0){
                        item.estimate.details = item.estimate.details.filter(item => !(item.feeId == 0));
                        item.estimate.details.forEach(function(item, index, self) {
                            if(!common.isNumeric(self[index].id)){
                                self[index].id = 0;
                            }
                        });
                    }
                });
            }
            if(this.entity.routes.length > 0){
                this.entity.routes.forEach(function(item, index, self) {
                    if(!common.isNumeric(self[index].id)){
                        self[index].id = 0;
                    }
                });
            }
            if(this.id > 0){
                if(this.action == 'add' || this.action == 'edit'){
                    this.edit();
                }
                else if(this.action == 'explain'){
                    this.explain();
                }
            }
            else{
                this.add();
            }
        },

        add(){
            this.setLoading(true);
            jobService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false);  });
        },

        edit(){
            this.setLoading(true);
            jobService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        explain(){
            this.setLoading(true);
            jobService.explain(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Start
        closeStart(item){
            this.startName = item.locationName;
            this.entity.startId = item.id;
            this.entity.start = item;
            this.getRoutes();
            this.$refs.locationStart.close();
        },

        openStart(){
            this.$refs.locationStart.open(0);
        },

        getStartSelected(val){
            this.entity.startId = val.id;
            this.entity.start = val;
            this.startName = val.locationName;
            this.getRoutes();
        },
        
        getStartList(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
            locationService.getLocations(search).then((response) => {
                if(response.statusCode == 200){
                    this.startList = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        //END
        closeEnd(item){
            this.endName = item.locationName;
            this.entity.endId = item.id;
            this.entity.end = item;
            this.getRoutes();
            this.$refs.locationEnd.close();
        },

        openEnd(){
            this.$refs.locationEnd.open(0);
        },

        getEndSelected(val){
            this.entity.endId = val.id;
            this.entity.end = val;
            this.endName = val.locationName;
            this.getRoutes();
        },
        
        getEndList(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, type: 0, code:  searchTerm };
            locationService.getLocations(search).then((response) => {
                if(response.statusCode == 200){
                    this.endList = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        //Fee
        closeFee(item){
            const selected = this.entity.details[this.index].estimate.details.findIndex(x => x.id == this.selectedId);
            this.entity.details[this.index].estimate.details[selected].fee = item;
            this.entity.details[this.index].estimate.details[selected].feeId = item.id;
            this.$refs.feeList.close();
            this.selectedId = '';
            this.index = -2;
        },

        openFee(index, id){
            this.index = index;
            this.selectedId = id;
            this.$refs.feeList.open();
        },

        //Vehicle
        closeVehicle(item){
            this.licensePlate = item.licensePlate;
            this.entity.vehicleId = item.id;
            if(item.driver !== null && item.driver.id > 0){
                this.entity.driverId = item.driver.id;
                this.driverName = item.driver.fullName;
            }
            this.$refs.vehicleList.close();
        },

        getVehicleSelected(val){
            this.entity.vehicleId = val.id;
            this.licensePlate = val.licensePlate;
            if(val.driver !== null && val.driver.id > 0){
                this.entity.driverId = val.driver.id;
                this.driverName = val.driver.fullName;
            }
        },

        openVehicle(){
            this.$refs.vehicleList.open();
        },

        getVehicles(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, licensePlate:  searchTerm };
            vehicleService.getVehicles(search).then((response) => {
                if(response.statusCode == 200){
                    this.vehicles = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        getMoocs(searchTerm){
            vehicleService.getById(this.entity.vehicleId).then((response) => {
                if(response.statusCode == 200){
                    if(response.data !== null && response.data.moocs !== null && response.data.moocs.length > 0){
                        this.moocs = response.data.moocs;
                    }
                    else{
                        this.getMoocAll(searchTerm);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },
        
        getMoocAll(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
            vehicleService.getVehicles(search).then((response) => {
                if(response.statusCode == 200){
                    this.moocs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        //Mooc
        closeMooc(item){
            this.moocCode = item.licensePlate;
            this.entity.moocId = item.id;
            this.$refs.moocList.close();
        },

        getMoocSelected(val){
            this.entity.moocId = val.id;
            this.moocCode = val.licensePlate;
        },

        openMooc(){
            this.$refs.moocList.open();
        },

        //Driver
        closeDriver(item){
            this.driverName = item.fullName;
            this.entity.driverId = item.id;
            this.$refs.driverList.close();
        },

        getDriverSelected(val){
            this.entity.driverId = val.id;
            this.driverName = val.fullName;
        },

        openDriver(){
            this.$refs.driverList.open();
        },

        getDrivers(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, code:  searchTerm };
            driverService.getDrivers(search).then((response) => {
                if(response.statusCode == 200){
                    this.drivers = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        getById(){
            this.loading = true;
            this.staffName = '';
            this.licensePlate = '';
            this.moocCode = '';
            this.driverName = '';
            this.startName = '';
            this.endName = '';
            jobService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.tab = this.entity.details[0].id;
                    this.staffName = this.entity.staff.fullName;
                    this.licensePlate = this.entity.vehicle.licensePlate;
                    this.moocCode = this.entity.mooc.licensePlate;
                    this.driverName = this.entity.driver.fullName;
                    if(this.entity.startId > 0){
                        this.startName = this.entity.start.locationName;
                    }
                    if(this.entity.endId > 0){
                        this.endName = this.entity.end.locationName;
                    }
                    
                    if(this.action == 'explain'){
                        if(this.entity.details.length > 0){
                            this.entity.details.map(item => {
                                if(!item.estimate.isExplain){
                                    item.estimate.details.map(obj => {
                                        obj.explainPrice = obj.estimateAmount;
                                        obj.explainVat = 0;
                                        obj.explainAmount = obj.estimateAmount;
                                    });
                                }
                            });
                        }
                    }

                    console.log('this.entity', this.entity)
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.loading = false; });
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.entity.deptId = response.data.departmentId;
                        this.staffName = response.data.fullName;
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getCode(staffId){
            this.setLoading(true);
            jobService.getCode(staffId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.jobCode = response.data.code;
                    this.entity.jobNumber = response.data.number;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Sales
        closeStaff(item){
            this.staffName = item.fullName;
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.getCode(item.id);
            this.$refs.staffList.close();
        },

        getStaffSelected(item){
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.staffName = item.fullName;
            this.getCode(item.id);
        },

        openStaff(){
            this.$refs.staffList.open();
        },

        getStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.staffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        getTypeOfTransport(id){
            return this.typeList.filter((obj) => obj.id == id)[0].text;
        },
        
        //Start
        closeStartLo(item){
            const selected = this.entity.routes.findIndex(x => x.id == this.selectedId);
            this.entity.routes[selected].start = item;
            this.entity.routes[selected].startId = item.id;
            this.$refs.startList.close();
            this.selectedId = '';
        },

        openStartLo(id){
            this.selectedId = id;
            this.$refs.startList.open();
        },

        //End
        closeEndLo(item){
            const selected = this.entity.routes.findIndex(x => x.id == this.selectedId);
            this.entity.routes[selected].end = item;
            this.entity.routes[selected].endId = item.id;
            this.$refs.endList.close();
            this.selectedId = '';
        },

        openEndLo(id){
            this.selectedId = id;
            this.$refs.endList.open();
        },
    },
    watch: { 
        staffName: function (val) { 
            if(val == ''){
                this.entity.staffId = 0;
            }
        },
        licensePlate: function (val) { 
            if(val == ''){
                this.entity.vehicleId = 0;
            }
        },
        moocCode: function (val) { 
            if(val == ''){
                this.entity.moocId = 0;
            }
        },
        driverName: function (val) { 
            if(val == ''){
                this.entity.driverId = 0;
            }
        },
        startName: function (val) { 
            if(val == ''){
                this.entity.startId = 0;
                if(this.id == 0){
                    this.getRoutes();
                }
            }
        },
        endName: function (val) { 
            if(val == ''){
                this.entity.endId = 0;
                if(this.id == 0){
                    this.getRoutes();
                }
            }
        },
    },
    validations: {
        entity: {
            jobCode: { required },
            jobDate: { required },
            submitDate: { required },
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            driverId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            vehicleId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            moocId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            routes: {
                $each: {
                    km: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    fuel: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                    price: {
                        required,
                        isSelected(value) {
                            if (value > 0) {
                                return true;
                            }
                            else{
                                return false;
                            }
                        }
                    },
                }
            }
        }
    }
})
</script>

<style>
    .tabs-order-vehicle .md-tabs-navigation {
        background-color: bisque !important;
        padding: 0;
        margin: 0 12px;
    }
</style>

<style scoped>
    .editable span {
        padding-right: 5px;
    }
    .editable a:hover {
        text-decoration: underline !important;
    }
    .btn-add {
        background-color: #007bff;
        padding: 7px 10px;
        color: #FFF !important;
        border-radius: 3px;
    }
    .btn-add i {
        color: #FFF !important;
    }
    .order-detail-list {
        border: 1px solid #ccc;
    }
    .box-item:first-child {
        padding-top: 5px;
    }
    .box-item {
        display: flex;
        justify-content: flex-start;
        margin: 5px 0;
    }
    .box-item > div:first-child {
        width: 38%;
    }
    .box-item > div:last-child {
        width: 62%;
    }
    .box-value {
        padding-left: 20px;
    }
    .box-1 .box-item > div:first-child {
        width: 33%;
    }
    .box-1 .box-item > div:last-child {
        width: 67%;
    }
    .box-3 .box-item > div:first-child {
        width: 30%;
    }
    .box-3 .box-item > div:last-child {
        width: 70%;
    }
</style>