<template>
    <md-dialog :md-active.sync="showDialog" :md-click-outside-to-close="false" style="z-index: 9994;">
        <md-content>
            <div class="page-content" style="height: 500px; min-height: 500px;">
                <div class="grid">
                    <div class="row no-gutters page-list-header">
                        <div class="col l-6 m-6 c-12">
                            <h4 class="page-list-title"><md-icon>note_add</md-icon> <span>{{title}}</span></h4>
                        </div>
                        <div class="col l-6 m-6 c-12">
                            <div class="page-list-action"> 
                                <md-button v-shortkey="['ctrl', 's']" @shortkey="submit()" @click="submit()" class="md-raised md-primary">Lưu<md-tooltip>Gửi yêu cầu phê duyệt (Ctrl + L)</md-tooltip></md-button>
                                <md-button @click="close()" class="md-raised">Đóng<md-tooltip>Đóng</md-tooltip></md-button>
                            </div>
                        </div>
                    </div>
                    <div class="page-list-body">
                        <div class="form-body">
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div class="group-box" style="margin-top:10px; margin-bottom: 10px;">
                                        <div class="group-title">THÔNG TIN VENDOR</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field>
                                                        <label for="jobCode">Mã điều động</label>
                                                        <md-input :disabled="true" name="jobCode" v-model="entity.jobCode"></md-input>
                                                    </md-field>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-datepicker v-model="entity.submitDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.submitDate.$error }">
                                                        <label>Ngày yêu cầu<span class="label-require">(*)</span></label>
                                                        <span class="md-error" v-if="submitted && !$v.entity.submitDate.required">Vui lòng chọn ngày yêu cầu</span>
                                                    </md-datepicker>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-datepicker v-model="entity.jobDate" md-immediately :md-model-type="String" :class="{'md-invalid': submitted && $v.entity.jobDate.$error }">
                                                        <label>Ngày thực hiện<span class="label-require">(*)</span></label>
                                                        <span class="md-error" v-if="submitted && !$v.entity.jobDate.required">Vui lòng chọn ngày thực hiện</span>
                                                    </md-datepicker>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="vendorName" @md-selected="getVendorSelected" :md-options="vendors" @md-changed="getVendors"  @md-opened="getVendors" :class="{'md-invalid': submitted && $v.entity.vendorId.$error }">
                                                            <label>Đơn vị vận chuyển</label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.companyAlias }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                            <span class="md-error" v-if="submitted && !$v.entity.vendorId.isSelected">Vui lòng chọn đơn vị vận chuyển</span>
                                                        </md-autocomplete>
                                                        <md-button @click="openVendor()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                        </md-button>
                                                    </div>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field>
                                                        <label for="driver">Lái xe</label>
                                                        <md-input name="driver" v-model="entity.driver"></md-input>
                                                    </md-field>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field>
                                                        <label for="vehicle">Biển số xe</label>
                                                        <md-input name="vehicle" v-model="entity.vehicle"></md-input>
                                                    </md-field>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field class="number-control" :class="{'md-invalid': submitted && $v.entity.transportCost.$error }">
                                                        <div>Cước vận chuyển:</div>
                                                        <number name="transportCost" v-model="entity.transportCost" v-bind="number"></number> 
                                                        <span class="md-error" v-if="submitted && !$v.entity.transportCost.required">Vui lòng nhập cước vận chuyển</span>
                                                    </md-field>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <div class="form-control">
                                                        <md-autocomplete v-model="staffName" @md-selected="getStaffSelected" :md-options="staffs" @md-changed="getStaffs"  @md-opened="getStaffs" :class="{'md-invalid': submitted && $v.entity.staffId.$error }">
                                                            <label>Nhân viên điều xe<span class="label-require">(*)</span></label>
                                                            <template slot="md-autocomplete-item" slot-scope="{ item, term }">
                                                                <md-highlight-text :md-term="term">{{ item.fullName }}</md-highlight-text>
                                                            </template>
                                                            <template slot="md-autocomplete-empty" slot-scope="{ term }">
                                                                "{{ term }}" Không tìm thấy!
                                                            </template>
                                                            <span class="md-error" v-if="submitted && !$v.entity.staffId.isSelected">Vui lòng chọn nhân viên phụ trách</span>
                                                        </md-autocomplete>
                                                        <md-button @click="openStaff()" class="md-icon-button">
                                                            <md-icon>manage_search</md-icon>
                                                            <md-tooltip>Tìm nhân viên</md-tooltip>
                                                        </md-button>
                                                    </div>
                                                </div>
                                                <div class="col l-4 m-4 c-12">
                                                    <md-field>
                                                        <label for="note">Ghi chú</label>
                                                        <md-input name="note" v-model="entity.note"></md-input>
                                                    </md-field>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col l-12 m-12 c-12">
                                    <div class="group-box" style="margin-top:10px; height: 120px;">
                                        <div class="group-title">THÔNG TIN CONT/KIỆN HÀNG</div>
                                        <div class="box-content">
                                            <div class="row">
                                                <div class="col l-12 m-12 c-12">
                                                    <div class="info">
                                                        <div class="item">
                                                            <span class="label">Số con't/Kiện:</span> 
                                                            <span class="value">{{ entity.orderDetail.serial }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Đơn vị:</span> 
                                                            <span class="value">{{ entity.orderDetail.unit.unitName }}</span> 
                                                        </div>
                                                        <div class="item">
                                                            <span class="label">Tuyến đường:</span> 
                                                            <span class="value">
                                                                <span v-for="(item, index) in entity.orderDetail.locations" :key="'location-' +index">
                                                                    {{ item.locationName }}
                                                                </span>
                                                            </span> 
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <vendorList ref="vendorList" title="công ty vận tải" @close="closeVendor"/>
                <staffList ref="staffList" @close="closeStaff"/>
            </div>
        </md-content>
    </md-dialog>
</template>

<script>
import common from '../../../mixins';
import { mapActions } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import messageBox from '../../../utils/messageBox';
import jobVendorService from '../../../api/jobVendorService';
import staffService from '../../../api/staffService';
import vendorList from '../../../components/popup/_TransportList.vue';
import companyService from '../../../api/companyService';
import staffList from '../../../components/popup/_StaffList.vue';

export default ({
    components: {
        vendorList,
        staffList
    },
    data() {
        return {
            showDialog: false,
            id: 0,
            title: '',
            submitted: false,
            loading: false,
            entity: { id: 0, orderDetail: { serial: '', unit: { unitName: '' } }, vendorId: 0, transportCost: 0, staffId: 0, staff: { fullName: '' }, jobCode: '', jobNumber: 0, submitDate: common.dateNow, jobDate: common.dateNow },
            staffs: [],
            staffName: '',
            vendors: [],
            vendorName: '',
            number: common.currencyF,
            orderDetail: {}
        }
    },
    created(){
        
    },
    methods: {
        ...mapActions('common', ['setLoading']),

        update(id, orderDetail){
            this.orderDetail = orderDetail;
            this.id = id;
            this.submitted = false;
            this.showDialog = true;
            this.title = "Điều xe ngoài";
            this.getById();
        },

        open(obj){
            this.getByAccount();
            this.id = 0;
            this.vendorName = '';
            this.entity.vendorId = 0;
            this.entity.orderDetail = obj;
            this.entity.orderId = obj.orderId;
            this.entity.orderDetailId = obj.id;
            this.submitted = false;
            this.showDialog = true;
            this.title = "Điều xe ngoài";
        },

        close(){
            this.showDialog = false;
        },

        submit(){
            this.submitted = true;
            this.$v.$touch();
            if (this.$v.$invalid) {
                return;
            }
            if(this.id == 0){
                this.add();
            }
            else{
                this.edit();
            }
        },

        add(){
            jobVendorService.add(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Thêm mới thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        edit(){
            jobVendorService.edit(this.entity).then((response) => {
                if(response.statusCode == 200){
                    messageBox.showMessage("Cập nhật thành công");
                    this.$emit('refresh');
                    this.showDialog = false;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getById(){
            this.loading = true;
            this.staffName = '';
            this.vendorName = '';
            jobVendorService.getById(this.id).then((response) => {
                if(response.statusCode == 200){
                    this.entity = response.data;
                    this.staffName = this.entity.staff.fullName;
                    this.vendorName = this.entity.vendor.companyAlias;
                    this.entity.orderDetail = this.orderDetail;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.loading = false; });
        },

        //Vendor
        closeVendor(item){
            this.vendorName = item.companyAlias;
            this.entity.vendor = item;
            this.entity.vendorId = item.id;
            this.$refs.vendorList.close();
        },

        getVendorSelected(val){
            this.vendorName = val.companyAlias;
            this.entity.vendorId = val.id;
            this.entity.vendor = val;
        },

        openVendor(){
            this.$refs.vendorList.open();
        },

        getVendors(searchTerm){
            let search = { pageIndex: 1, pageSize: 30,  code: searchTerm };
            companyService.getTransport(search).then((response) => {
                if(response.statusCode == 200){
                    this.vendors = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        getByAccount(){
            this.setLoading(true);
            staffService.getByAccount().then((response) => {
                if(response.statusCode == 200){
                    if(response.data != null){
                        this.entity.staff = response.data;
                        this.entity.staffId = response.data.id;
                        this.entity.deptId = response.data.departmentId;
                        this.staffName = response.data.fullName;
                        this.getCode(this.entity.staffId);
                    }
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        getCode(staffId){
            this.setLoading(true);
            jobVendorService.getCode(staffId).then((response) => {
                if(response.statusCode == 200){
                    this.entity.jobCode = response.data.code;
                    this.entity.jobNumber = response.data.number;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally(() => { this.setLoading(false); });
        },

        //Sales
        closeStaff(item){
            this.staffName = item.fullName;
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.getCode(item.id);
            this.$refs.staffList.close();
        },

        getStaffSelected(item){
            this.entity.staffId = item.id;
            this.entity.deptId = item.departmentId;
            this.staffName = item.fullName;
            this.getCode(item.id);
        },

        openStaff(){
            this.$refs.staffList.open();
        },

        getStaffs(searchTerm){
            let search = { pageIndex: 1, pageSize: 30, name:  searchTerm };
            staffService.getStaffs(search).then((response) => {
                if(response.statusCode == 200){
                    this.staffs = response.data.items;
                }
                else{
                    messageBox.showWarning(response.data);
                }
            }).finally();
        },

        uuidv4(id) {
            let uuid = "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
                (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
            );

            return uuid + "-" + id;
        },
    },
    validations: {
        entity: {
            vendorId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            staffId: {
                required,
                isSelected(value) {
                    if (value > 0) {
                        return true;
                    }
                    else{
                        return false;
                    }
                }
            },
            jobCode: { required },
            jobDate: { required },
            submitDate: { required },
            transportCost: { required },
        }
    }
})
</script>

<style lang="css" scoped>
    .md-content {
        max-width: 1200px;
        width: 1200px;
    }
    .item {
        padding: 3px 5px;
    }
    .label {
        font-size: 15px;
        font-weight: bold;
        padding-right: 10px;
    }
    .value {
        font-size: 15px;
    }
</style>
