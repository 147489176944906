import { render, staticRenderFns } from "./_ContractList.vue?vue&type=template&id=bf0543e8&scoped=true&"
import script from "./_ContractList.vue?vue&type=script&lang=js&"
export * from "./_ContractList.vue?vue&type=script&lang=js&"
import style0 from "./_ContractList.vue?vue&type=style&index=0&id=bf0543e8&lang=css&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bf0543e8",
  null
  
)

export default component.exports